<template>
  <div>
    <Loading v-if="isLoading" />
    <div v-else>
      <v-list subheader three-line v-if="items.length > 0">
        <v-subheader v-text="$t('listChat.today')"></v-subheader>

        <template v-for="(item, index) in items">
          <v-list-item
            v-if="item.subtitle.length > 0"
            :key="item.id"
            :ripple="{ class: 'deep-purple--text' }"
            @click.prevent="redirectChat(item.id)"
          >
            <v-list-item-avatar>
              <v-icon class="deep-purple accent-4" dark>
                mdi-account
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-html="clientFullName(item)"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text
                v-text="formatDate(item.action)"
              ></v-list-item-action-text>

              <v-icon color="yellow darken-3" v-if="item.haveMessages">
                mdi-message
              </v-icon>
              <v-icon color="deep-purple lighten-5" v-else>
                mdi-message-outline
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset :key="index + 'A'" />
        </template>
      </v-list>
      <Empty v-else icon="message-outline" :msg="$t('listChat.noMessages')" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const Loading = () =>
  import(/* webpackChunkName: "Loading" */ "@/components/loading.vue");
const Empty = () =>
  import(/* webpackChunkName: "Empty" */ "@/components/empty.vue");

export default {
  name: "ListChat",
  components: { Loading, Empty },
  props: {},
  data: () => ({
    isLoading: true,
  }),
  methods: {
    formatDate(date) {
      return this.$moment(date.toDate())
        .locale("es")
        .fromNow();
    },
    ...mapActions("ui", {
      disabledNavigation: "disabledNavigation",
      activeBackBtn: "activeBackBtn",
    }),
    redirectChat(id) {
      setTimeout(() => {
        this.$router.push({ params: { id }, name: "Chat" });
      }, 500);
    },
    clientFullName(item) {
      try {
        return item.client.firstName;
      } catch (err) {
        return item.id;
      }
    },
  },
  computed: {
    ...mapState("chat", {
      items: (state) => state.activeChats,
    }),
  },
  mounted() {
    //console.log(s)
    // Disabled navigation
    setTimeout(() => {
      this.disabledNavigation();
      this.activeBackBtn();
      this.isLoading = false;
    }, 250);

    //this.authFirebase();
  },
};
</script>
